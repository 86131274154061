import {
  User,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  Purchases,
  PurchasesOfferings,
  PurchasesStoreProduct,
} from "@revenuecat/purchases-capacitor";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

import db, { auth } from "../db/firebase";
import { isPlatform } from "@ionic/react";
import { OrgData } from "../models/organization";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { createOrganization } from "../services/useOrganizationsService";
import organizationConverter from "../db/converters/organizationConverter";
import { Capacitor } from "@capacitor/core";

type ACCOUNT_STATUS = "trial" | "subscribed" | "unsubscribed";
export const AuthContext = createContext<{
  login: Function;
  logout: Function;
  isLoading: boolean;
  trialPeriod: boolean;
  currentUser: User | null;
  accountStatus: ACCOUNT_STATUS;
  currentOrg: OrgData | undefined;
  restorePurchases: () => Promise<void>;
  appOfferings: PurchasesOfferings | undefined;
  recoverPassword: (email: string) => Promise<void>;
  subscribe: (product: PurchasesStoreProduct) => Promise<void>;
  updateVersionCheckDate: (date: string, platform: string) => Promise<void>;
  signup: (email: string, password: string, orgData: OrgData) => Promise<void>;
} | null>(null);

export const AuthProvider = ({ children }: { children: any }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trialPeriod, setTrialPeriod] = useState(false);
  const [currentOrg, setCurrentOrg] = useState<OrgData | undefined>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [accountStatus, setAccountStatus] =
    useState<ACCOUNT_STATUS>("unsubscribed");
  const [appOfferings, setAppOfferings] = useState<PurchasesOfferings>();

  useEffect(() => {
    const initRevenueCat = async () => {
      if (Capacitor.getPlatform() === "ios") {
        await Purchases.configure({
          apiKey: "appl_bUUDMaCAiWcbHdcjxrViuyqIJQY",
        });
      } else if (Capacitor.getPlatform() === "android") {
        console.log("android revenue");

        await Purchases.configure({
          // apiKey: "goog_IpNZQzFRWYJffoOyXYMNNXICHeV",
          apiKey: "goog_vYRiRMJpyMNlTxVunfPfipNfMGr",
        });
      }
    };

    onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      let status: ACCOUNT_STATUS = "unsubscribed";
      let organization = undefined;
      if (isPlatform("mobile") && !isPlatform("mobileweb")) {
        //initGlassfy();
        //initQonversion();
        initRevenueCat();
      }

      if (user) {
        const organizationDocRef = doc(
          db,
          `organizations/${user.uid}`
        ).withConverter(organizationConverter);
        const organizationDoc = await getDoc(organizationDocRef);
        organization = organizationDoc.data();
      }

      if (organization) {
        updateDoc(doc(db, "organizations", organization.id!), {
          lastLogin: Timestamp.now(),
        });
      }
      console.log("organization", organization);

      if (organization && isPlatform("mobile") && !isPlatform("mobileweb")) {
        try {
          const offerings = await Purchases.getOfferings();

          await Purchases.logIn({ appUserID: organization.id! });

          await setUserAttributes(organization);

          const { customerInfo } = await Purchases.getCustomerInfo();

          if (
            customerInfo.entitlements.active &&
            customerInfo.entitlements.active["annual_subscription"]
          ) {
            const { isActive, expirationDate } =
              customerInfo.entitlements.active["annual_subscription"];

            if (isActive) {
              const formattedExpireDate =
                moment(expirationDate).format("MM/DD/YYYY");
              updateDoc(doc(db, "organizations", organization.id!), {
                expireDate: formattedExpireDate,
              });
              organization.expireDate = formattedExpireDate;

              status = "subscribed";
            } else if (organization.expireDate) {
              const currentDate = moment(
                moment().format("MM/DD/YYYY"),
                "MM/DD/YYYY"
              );
              const expirationDate = moment(
                organization.expireDate,
                "MM/DD/YYYY"
              );
              if (currentDate.isSameOrBefore(expirationDate)) {
                status = "subscribed";
              }
            } else if (organization.expireDate) {
              const currentDate = moment(
                moment().format("MM/DD/YYYY"),
                "MM/DD/YYYY"
              );
              const expirationDate = moment(
                organization.expireDate,
                "MM/DD/YYYY"
              );
              if (currentDate.isSameOrBefore(expirationDate)) {
                status = "subscribed";
              }
            }
          }

          setAppOfferings(offerings);
        } catch (err) {
          alert(err);
        }
      }

      if (organization && (isPlatform("desktop") || isPlatform("mobileweb"))) {
        const apiKey = process.env.REACT_APP_REVENUE_CAT_API_KEY;
        const projectId = process.env.REACT_APP_REVENUE_CAT_PROJECT_ID;

        try {
          const response = await fetch(
            `https://api.revenuecat.com/v2/projects/${projectId}/customers/${organization.id}/active_entitlements`,
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status !== 200) {
            if (response.status === 401) throw new Error("Invalid API Key.");
            if (response.status === 404) {
              if (organization.expireDate) {
                const currentDate = moment(
                  moment().format("MM/DD/YYYY"),
                  "MM/DD/YYYY"
                );
                const expirationDate = moment(
                  organization.expireDate,
                  "MM/DD/YYYY"
                );
                if (currentDate.isSameOrBefore(expirationDate)) {
                  status = "subscribed";
                } else {
                  throw new Error(
                    "No subscription was found for the given user."
                  );
                }
              } else {
                throw new Error(
                  "No subscription was found for the given user."
                );
              }
            }

            throw new Error(
              "An error occurred while fetching the subscription status."
            );
          }

          const data = await response.json();

          if (data.items && data.items[0]) {
            console.log("revenue cat subcription data", data);

            status = "subscribed";
            const formattedExpireDate = moment(data.items[0].expires_at).format(
              "MM/DD/YYYY"
            );
            organization.expireDate = formattedExpireDate;
          } else {
            console.log("organization", organization);

            if (organization.expireDate) {
              const currentDate = moment(
                moment().format("MM/DD/YYYY"),
                "MM/DD/YYYY"
              );
              const expirationDate = moment(
                organization.expireDate,
                "MM/DD/YYYY"
              );
              if (currentDate.isSameOrBefore(expirationDate)) {
                status = "subscribed";
              } else {
                throw new Error(
                  "No subscription was found for the given user."
                );
              }
            } else {
              throw new Error("No subscription was found for the given user.");
            }
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (organization && organization.expireDate) {
        const currentDate = moment(moment().format("MM/DD/YYYY"), "MM/DD/YYYY");
        const expirationDate = moment(organization.expireDate, "MM/DD/YYYY");
        if (currentDate.isSameOrBefore(expirationDate)) {
          status = "subscribed";
        }
      }

      if (user && status !== "subscribed") {
        const organizationDocRef = doc(db, `organizations/${user.uid}`);
        const organizationDoc = await getDoc(organizationDocRef);
        const organizationData = organizationDoc.data() as OrgData;
        const trialDays = organizationData.trialDays!;
        const trialExpireDate = organizationData.trialExpireDate;
        const today = moment();
        const createdDate = moment(organizationData.createdAt);

        if (trialExpireDate) {
          const currentDate = moment(
            moment().format("MM/DD/YYYY"),
            "MM/DD/YYYY"
          );
          const trialEnd = moment(trialExpireDate, "MM/DD/YYYY");

          if (currentDate.isSameOrBefore(trialEnd)) {
            setTrialPeriod(true);
            status = "trial";
          }
        } else {
          setTrialPeriod(today.diff(createdDate, "days") <= trialDays);
          status =
            today.diff(createdDate, "days") <= trialDays
              ? "trial"
              : "unsubscribed";
        }
      }

      setCurrentUser(user);
      setAccountStatus(status);
      setCurrentOrg(organization);
      setIsLoading(false);
    });
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      throw err;
    }
  };

  const signup = async (email: string, password: string, orgData: OrgData) => {
    try {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await createOrganization(credentials.user.uid, orgData);
    } catch (err) {
      console.log(err);
    }
  };

  const recoverPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      throw err;
    }
  };

  const logout = () => {
    auth.signOut();
  };

  const setUserAttributes = async (organization: OrgData) => {
    try {
      await Purchases.setAttributes({
        $displayName: `${organization.firstName} ${organization.lastName}`,
        $email: organization.email,
      });
      console.log("set user attributes");
    } catch (err) {}
  };

  const restorePurchases = async () => {
    try {
      const { customerInfo } = await Purchases.restorePurchases();
      if (
        currentOrg &&
        customerInfo.entitlements.active &&
        customerInfo.entitlements.active["annual_subscription"]
      ) {
        const { isActive, expirationDate } =
          customerInfo.entitlements.active["annual_subscription"];

        if (isActive) {
          const formattedExpireDate = moment(
            // .unix(expirationDateMillis)
            expirationDate
          ).format("MM/DD/YYYY");
          updateDoc(doc(db, "organizations", currentOrg.id!), {
            expireDate: formattedExpireDate,
          });

          setAccountStatus("subscribed");
          setCurrentOrg({
            ...currentOrg,
            expireDate: formattedExpireDate,
          });
        }
      }
    } catch (err) {}
  };

  const subscribe = async (product: PurchasesStoreProduct) => {
    try {
      const { customerInfo } = await Purchases.purchaseStoreProduct({
        product,
      });

      if (
        currentOrg &&
        customerInfo.entitlements.active &&
        customerInfo.entitlements.active["annual_subscription"]
      ) {
        const { isActive, expirationDate } =
          customerInfo.entitlements.active["annual_subscription"];

        if (isActive) {
          const formattedExpireDate = moment(
            // .unix(expirationDateMillis)
            expirationDate
          ).format("MM/DD/YYYY");
          updateDoc(doc(db, "organizations", currentOrg.id!), {
            expireDate: formattedExpireDate,
          });

          setAccountStatus("subscribed");
          setCurrentOrg({
            ...currentOrg,
            expireDate: formattedExpireDate,
          });
        }
      }
    } catch (err) {}

    // if (currentOrg && transaction.receiptValidated) {
    //   const { expireDate } = transaction.permissions.all[0];
    //   const formatedExpireDate = moment
    //     .unix(parseInt(expireDate))
    //     .format("MM/DD/YYYY");
    //   updateDoc(doc(db, "organizations"), currentOrg.id!, {
    //     expireDate: formatedExpireDate,
    //     paidSubscriptionExpiryDate: formatedExpireDate,
    //     paidSubscriptionStartDate: moment().format("MM/DD/YYYY"),
    //   });

    //   setAccountStatus("subscribed");
    // setCurrentOrg({
    //   ...currentOrg,
    //   expireDate: formatedExpireDate,
    // });
    // }
  };

  const updateVersionCheckDate = async (date: string, platform: string) => {
    const platformDate = { [`versionNumberLastCheckDate${platform}`]: date };

    updateDoc(doc(db, "organizations", currentOrg!.id!), {
      ...platformDate,
    });

    if (isPlatform("ios")) {
      setCurrentOrg({
        ...currentOrg!,
        versionNumberLastCheckDateIos: date,
      });
    }

    if (isPlatform("android")) {
      setCurrentOrg({
        ...currentOrg!,
        versionNumberLastCheckDateAndroid: date,
      });
    }
  };

  const values = {
    login,
    signup,
    logout,
    subscribe,
    isLoading,
    currentOrg,
    currentUser,
    trialPeriod,
    appOfferings,
    accountStatus,
    recoverPassword,
    restorePurchases,
    updateVersionCheckDate,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth has to be used within <AuthContext.Provider>");
  }

  return auth;
};
